import * as t from 'io-ts';
import { parseMessage } from './common';

const stringOrUndefined = t.union([t.string, t.undefined]);

const FortnoxInvoicesCodec = t.type({
  messageId: t.string,
  type: t.literal('fortnox-invoices'),
  invoiceType: t.union([
    t.literal('customer'),
    t.literal('supplier'),
    t.literal('both'),
  ]),
  clientId: t.string,
  fortnoxId: t.string,
  lastUpdateDate: t.string,
  years: t.array(t.string),
  onlyPayments: t.boolean,
  credentials: t.union([
    t.type({
      credentialsType: t.union([
        t.literal('FortnoxClassic'),
        t.literal('FortnoxWhiteLabelOrganisation'),
        t.literal('FortnoxWhiteLabelClient'),
        t.literal('FortnoxOrganisationOAuth'),
        t.literal('FortnoxOauth'),
      ]),
      accessToken: t.string,
    }),
    t.null,
  ]),
  fortnoxTenantId: stringOrUndefined,
  fortnoxUserId: stringOrUndefined,
});

/**
 * Message format for starting synchronize fortnox invoices.
 * Used by the sie-import lamdba.
 */
export type FortnoxInvoicesMessage = t.TypeOf<typeof FortnoxInvoicesCodec>;

/**
 * parseFortnoxInvoices
 *
 * @param json message in string
 * @returns A result object with a valid message.
 */
export const parseFortnoxInvoices = (json: string) =>
  parseMessage(FortnoxInvoicesCodec, json);

/**
 * Fortnox invoices synchronization
 * result send as notification messages
 */
export const FortnoxInvoicesStateCodec = t.type({
  clientId: t.string,
  topic: t.union([
    t.literal('fortnox-customer-invoices'),
    t.literal('fortnox-supplier-invoices'),
  ]),
  status: t.union([
    t.literal('completed'),
    t.literal('running'),
    t.literal('invoices_fail'),
    t.literal('payments_fail'),
  ]),
});
export type FortnoxInvoicesStateMessage = t.TypeOf<
  typeof FortnoxInvoicesStateCodec
>;
