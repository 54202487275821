import * as t from 'io-ts';
import { parseMessage } from './common';

const SieImportMessageCodec = t.type({
  type: t.literal('sie-import'),
  messageId: t.string,
  organisationId: t.string,
  clientId: t.string,
  userId: t.string,
  financialYearId: t.number,
});

/**
 * Message format for starting an import of SIE files.
 * Used by the sie-import lamdba.
 */
export type SieImportMessage = t.TypeOf<typeof SieImportMessageCodec>;

/**
 * parseSieImportMessage
 *
 * @param json message in string
 * @returns A result object with a valid message.
 */
export const parseSieImportMessage = (json: string) =>
  parseMessage(SieImportMessageCodec, json);
