import React from 'react';
import { Switch } from 'react-router-dom';

import { compose } from 'redux';
import config from '_shared/services/config';
import VersionView from '_shared/components/VersionView';
import NotFoundView from '_shared/components/NotFoundView';
import CreateOrganizationView from '_organization/components/CreateOrganizationView';
import ClientsView from '_clients/components/ClientsView';
import ClientProductView from '_clients/components/ClientProductView';
import NoFinancialYears from '_clients/components/NoFinancialYears';
import MaintenancePage from '_clients/components/MaintenancePage';
import LoginView from '_authentication/components/AuthorizationView';
import SessionTimedOut from '_authentication/components/FnWhiteLabel/SessionTimedOut';
import LoginReplacement from '_authentication/components/FnWhiteLabel/LoginReplacement';
import UserChanged from '_authentication/components/FnWhiteLabel/UserChanged';
import SkatteverketIntegrationView from '_integration/components/SkatteverketIntegrationView';

import RegistrationView from '_authentication/components/AuthorizationView/RegisterView';
import ConfirmView from '_authentication/components/AuthorizationView/ConfirmView';
import ClientView from '_clients/components/ClientView';
import CreateClient from '_clients/components/CreateClient';
import FortnoxRedirectReceiver from '_integration/components/FortnoxRedirectReceiver';
import withLicenseRouteGuard from '_payment/licenses/withLicenseRouteGuard';
import withAccountingBalancesListener from '_shared/services/Notifications/withAccountingBalancesListener';
import withSieImportListener from '_shared/services/Notifications/withSieImportListener';
import withPaywall from '_payment/payment-status/withPaywall';
import { withLicenseStatusFetcher } from '_payment/licenses/useLicense';
import { withPaymentStatusContext } from '_payment/payment-status/usePaymentStatus';
import FeatureFlags from 'routes/feature-flags';
import PersonProductView from '_person/components/person-product-view';
import withReduxSeeding from '_person/HOCs/withReduxSeeding';
import withClientInformation from '_clients/context/WithClientInformation';
import NewReconciliationView from '_reconciliation/components/ReconciliationView';
import PrintedDocument from '_shared/components/PrintedDocument/PrintedDocument';
import { withPiwikAnalytics } from '_shared/HOC';
import Route from '_shared/components/Route';
import withAgoyPackageRouteGuard from '_authorization/licenses/withAgoyPackageRouteGuard';
import { withNewSpecificationsFeatureSwitchContext } from '_shared/HOC/withNewSpecificationsFeatureSwitchContext';

import {
  withAppProviders,
  withAuthenticationRouteGuard,
  withPrintProviders,
  withInitialFetch,
  withStyling,
  withFortnoxCompanyTenantRouteGuard,
} from '../AppProviders';
import PaymentUnpaid from './payment/unpaid';
import PaymentCheckout from './payment/checkout';
import PaymentCancel from './payment/cancel';
import PaymentSuccess from './payment/success';
import FortnoxIntegrationFailure from './auth/fortnox/failure';
import OrganisationView from './organisation';
import ProfileView from './profile';
import CreateClientPerson from './person/create';
import ViewClientPerson from './person/view';
import ListClientPersons from './person/list';
import { FORTNOX_ROUTE_PREFIX } from '../contants';
import withFortnoxVesselEvents from '_shared/HOC/withFortnoxVesselEvents';

type RouteConfig = {
  path: string;
  key: string;
  exact: boolean;
  component: React.FC<any & RouteConfig>;
  routes?: RouteConfig[];
};

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
const RouteWithSubRoutes = (route: RouteConfig): JSX.Element => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => <route.component {...props} routes={route.routes} />}
  />
);

/**
 * Use this component for any new section of routes (any config object that has a "routes" property)
 */
export const RenderRoutes: React.FC<{ routes: RouteConfig[] }> = ({
  routes,
}: {
  routes: RouteConfig[];
}) => (
  <Switch>
    {routes.map((route) => {
      const { key, ...rest } = route;

      return <RouteWithSubRoutes key={key} {...rest} />;
    })}
    <Route component={NotFoundView} />
  </Switch>
);

/**
 * These routes will only render when the user is authenticated
 */
const AUTHENTICATED_ROUTES = [
  {
    path: '/payment/unpaid',
    key: 'PAYMENT-UNPAID',
    exact: true,
    component: PaymentUnpaid,
  },
  {
    path: '/payment/success',
    key: 'PAYMENT-SUCCESS',
    exact: true,
    component: PaymentSuccess,
  },
  {
    path: '/payment/cancel',
    key: 'PAYMENT-CANCEL',
    exact: true,
    component: PaymentCancel,
  },
  {
    path: '/payment/checkout',
    key: 'PAYMENT-CHECKOUT',
    exact: true,
    component: PaymentCheckout,
  },
  {
    path: '/organisation/general',
    key: 'ORGANISATION-GENERAL',
    exact: true,
    component: OrganisationView,
  },
  {
    path: '/organisation/settings',
    key: 'ORGANISATION-SETTINGS',
    exact: false,
    component: OrganisationView,
  },
  {
    path: '/organisation/members',
    key: 'ORGANISATION-MEMBERS',
    exact: true,
    component: OrganisationView,
  },
  {
    path: '/organisation/clients',
    key: 'ORGANISATION-CLIENTS',
    exact: true,
    component: OrganisationView,
  },
  {
    path: '/organisation/payment-details',
    key: 'ORGANISATION-PAYMENT-DETAILS',
    exact: true,
    component: OrganisationView,
  },
  {
    path: '/organisation/licenses',
    key: 'ORGANISATION-LICENSES',
    exact: true,
    component: OrganisationView,
  },
  {
    path: '/organisation/checklists',
    key: 'ORGANISATION-CHECKLISTS',
    exact: false,
    component: OrganisationView,
  },
  {
    path: '/create-organisation',
    key: 'CREATE_ORGANISATION',
    exact: true,
    component: CreateOrganizationView,
  },
  {
    path: '/profile',
    key: 'PROFILE',
    exact: true,
    component: ProfileView,
  },
  {
    path: '/auth/skatteverket',
    key: 'AUTH_SKATTEVERKET',
    exact: true,
    component: SkatteverketIntegrationView,
  },
  {
    path: '/auth/fortnox/failure',
    key: 'AUTH_FORTNOX_FAILURE',
    exact: true,
    component: FortnoxIntegrationFailure,
  },
  {
    path: '/',
    key: 'CLIENTS_VIEW',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(ClientsView),
  },
  {
    path: '/clients/create',
    key: 'CREATE_CLIENT',
    exact: true,
    component: CreateClient,
  },
  {
    path: '/clients/:clientId',
    key: 'CLIENT_VIEW',
    exact: true,
    component: withSieImportListener(ClientView),
  },
  {
    path: '/persons/create',
    key: 'CREATE_PERSON',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(CreateClientPerson),
  },
  {
    path: '/persons',
    key: 'PERSONS_VIEW',
    exact: true,
    component: compose(
      withFortnoxCompanyTenantRouteGuard,
      withLicenseRouteGuard
    )(ListClientPersons),
  },
  {
    path: '/persons/:personId',
    key: 'PERSON_VIEW',
    exact: true,
    component: compose(withLicenseRouteGuard)(ViewClientPerson),
  },
  {
    path: '/persons/:personId/connections',
    key: 'PERSON_VIEW_CONNECTIONS',
    exact: true,
    component: ViewClientPerson,
  },
  {
    path: '/persons/:personId/connections/create',
    key: 'PERSON_VIEW_CONNECTIONS_CREATE',
    exact: true,
    component: ViewClientPerson,
  },
  {
    path: '/persons/:personId/connections/:connectionId',
    key: 'PERSON_VIEW_CONNECTIONS_CREATE',
    exact: true,
    component: ViewClientPerson,
  },
  {
    path: '/persons/:personId/:program',
    key: 'PERSON_PROGRAMS',
    exact: false,
    component: compose(withLicenseRouteGuard)(
      withReduxSeeding(PersonProductView)
    ),
  },
  {
    path: '/clients/:clientId/documents',
    key: 'CLIENT_VIEW_DOCUMENTS',
    exact: true,
    component: ClientView,
  },
  {
    path: '/clients/:clientId/integrations',
    key: 'CLIENT_VIEW_INTEGRATIONS',
    exact: true,
    component: ClientView,
  },
  {
    path: '/clients/:clientId/activity-log',
    key: 'CLIENT_VIEW_ACTIVITY-LOG',
    exact: true,
    component: ClientView,
  },
  {
    path: '/clients/:clientId/connections',
    key: 'CLIENT_VIEW_CONNECTIONS',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(ClientView),
  },
  {
    path: '/clients/:clientId/connections/create',
    key: 'CLIENT_VIEW_CONNECTIONS_CREATE',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(ClientView),
  },
  {
    path: '/clients/:clientId/connections/:connectionId',
    key: 'CLIENT_VIEW_CONNECTIONS_DETAILS',
    exact: true,
    component: ClientView,
  },
  {
    path: '/clients/:clientId/shares',
    key: 'CLIENT_VIEW_CONNECTIONS_SHARES',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(ClientView),
  },
  {
    path: '/clients/:clientId/owners',
    key: 'CLIENT_VIEW_CONNECTIONS_OWNERS',
    exact: true,
    component: withFortnoxCompanyTenantRouteGuard(ClientView),
  },
  {
    path: '/clients/:clientId/client-information',
    key: 'CLIENT_VIEW_CLIENT_INFORMATION',
    exact: true,
    component: ClientView,
  },
  {
    path: '/clients/:clientId/empty',
    key: 'CLIENT_VIEW_EMPTY',
    exact: true,
    component: NoFinancialYears,
  },
  {
    path: '/clients/:clientId/reconciliation',
    key: 'NEW_CLIENT_DETAILS_VIEW',
    exact: true,
    component: NewReconciliationView,
  },
  {
    path: '/clients/:clientId/:program(reconciliation)/:financialYear',
    key: 'NEW_CLIENT_DETAILS_VIEW',
    exact: false,
    component: compose(
      withSieImportListener,
      withLicenseRouteGuard,
      withNewSpecificationsFeatureSwitchContext
    )(NewReconciliationView),
  },
  {
    path: '/clients/:clientId/:financialYear/:program',
    key: 'CLIENT_PROGRAMS',
    exact: false,
    component: compose(
      withAccountingBalancesListener,
      withSieImportListener,
      withLicenseRouteGuard,
      withNewSpecificationsFeatureSwitchContext
    )(ClientProductView),
  },
];

/**
 * Web app's central route configuration.
 * Inspired by https://www.ryanjyost.com/react-routing/
 */
const ROUTES: RouteConfig[] = [
  {
    path: '/yearend/print',
    key: 'PRINT',
    exact: false,
    component: withPrintProviders(PrintedDocument),
  },
  {
    path: '/user-changed',
    key: 'USER_CHANGED',
    exact: true,
    component: withStyling(UserChanged),
  },
  {
    path: '/',
    key: 'STANDARD_APP',
    exact: false,
    component: withAppProviders(RenderRoutes),
    routes: [
      {
        path: '/feature-flags',
        key: 'FEATURE_FLAGS',
        exact: true,
        component: FeatureFlags,
      },
      {
        path: '/timeout',
        key: 'TIMEOUT',
        exact: true,
        component: SessionTimedOut,
      },
      {
        path: '/login',
        key: 'LOGIN',
        exact: true,
        component:
          config.whiteLabel === 'fortnox' ? LoginReplacement : LoginView,
      },
      {
        path: '/register',
        key: 'REGISTER',
        exact: true,
        component: RegistrationView,
      },
      {
        path: '/confirm',
        key: 'CONFIRM',
        exact: true,
        component: ConfirmView,
      },
      {
        path: '/version',
        key: 'VERSION',
        exact: true,
        component: compose(withAuthenticationRouteGuard)(VersionView),
      },
      {
        path: '/fn-login',
        key: 'FN_LOGIN',
        exact: true,
        component: FortnoxRedirectReceiver,
      },
      {
        path: `${FORTNOX_ROUTE_PREFIX}/sync`,
        key: 'FORTNOX_SYNC',
        exact: true,
        component: FortnoxRedirectReceiver,
      },
      {
        path: '/maintenance',
        key: 'MAINTENANCE',
        exact: true,
        component: MaintenancePage,
      },
      {
        path: '/',
        key: 'ROOT',
        exact: false,
        component: compose(
          ...[
            withClientInformation,
            withAuthenticationRouteGuard,
            ...(config.isFortnoxCloud
              ? [withFortnoxVesselEvents, withAgoyPackageRouteGuard]
              : []),
            withInitialFetch,
            withPaymentStatusContext,
            withPaywall,
            withLicenseStatusFetcher,
            // TODO: AGOY-9438
            ...(!config.isFortnoxCloud ? [withPiwikAnalytics] : []),
          ]
        )(RenderRoutes),
        routes: config.isFortnoxCloud
          ? AUTHENTICATED_ROUTES.map((user) => ({
              ...user,
              path: `${FORTNOX_ROUTE_PREFIX}${user.path}`,
            }))
          : AUTHENTICATED_ROUTES,
      },
    ],
  },
];

export default ROUTES;
