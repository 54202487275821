import React from 'react';
import { useIntl } from 'react-intl';
import FileDropArea from '_shared/components/FileDropArea/FileDropArea';
import { Period } from '@agoy/api-sdk-core';

interface PropTypes {
  onDrop: (file: File, id: number) => void;
  className?: string;
  onEnter?: (event: React.DragEvent) => void;
  onLeave?: (event: React.DragEvent) => void;
  children: React.ReactNode;
  period?: Period;
}

const anyFile = (_: File) => true;

const ImageDropArea = ({
  className,
  onDrop,
  onEnter,
  onLeave,
  children,
  period,
}: PropTypes) => {
  const { formatMessage } = useIntl();

  return (
    <FileDropArea
      className={className}
      onEnter={onEnter}
      onLeave={onLeave}
      onDrop={onDrop}
      isValidFile={anyFile}
      onInvalidFileType={() =>
        window.alert(formatMessage({ id: 'hidden.invalidFileType' }))
      }
      period={period}
    >
      {children}
    </FileDropArea>
  );
};

export default ImageDropArea;
