import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFid from '../hooks/useFid';
import config from '../services/config';
import useHistory from '../hooks/useHistory';

const withFortnoxVesselEvents = (Component) => (props) => {
  const history = useHistory();
  const location = useLocation();
  const fid = useFid();

  useEffect(() => {
    if (!fid || !location.pathname.includes(fid)) return;

    // TODO: AGOY-9742
    if (location.pathname.endsWith('/')) {
      history.replace(
        `${location.pathname.slice(0, -1)}${location.search}${location.hash}`
      );
      return;
    }

    window.parent.postMessage({
      type: 'route-change-v1',
      route: `${location.pathname}${location.search}${location.hash}`.replace(
        `/${fid}`,
        ''
      ),
    });
  }, [fid, history, location.pathname, location.search, location.hash]);

  useEffect(() => {
    if (!config.isFortnoxCloud) return;

    const handleClick = () => {
      window.parent.postMessage({
        type: 'child-click-v1',
      });
    };

    document.addEventListener('click', handleClick);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return <Component {...props} />;
};

export default withFortnoxVesselEvents;
