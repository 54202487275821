import {
  id,
  account,
  multiply,
  sum,
  or,
  ref,
  value,
  sumAllowNull,
  table,
  sumAllowEmpty,
  ifOrElse,
  div,
  max,
  abs,
} from '@agoy/document';
import { differenceInMonths } from 'date-fns';
import { parse } from '@agoy/dates';

import {
  mapFormId,
  balanceSheetRef as balanceSheetUtilRef,
  incomeStatementRef as incomeStatementUtilRef,
  negative,
  onlyNegative,
  onlyPositive,
  absolute,
  toConfig,
  toInitial,
  toStructure,
} from '../forms-util';
import { financialYearToSKVPeriod } from '../../skvPeriod';
import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
  helpStructureINK2,
} from '../../types';
import { finalTaxCalculations, getFinalCalculationsMetadata } from '../shared';

const balanceSheetRef = (reference: string) =>
  balanceSheetUtilRef(reference, true);
const incomeStatementRef = (reference: string) =>
  incomeStatementUtilRef(reference, true);

const configINK2 = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  // The SRU config for SKV-2002-to-2023P3 is slightly different from the previous ones
  // For years before 2022P4 we return a different config
  const isFrom2022P4 =
    financialYearToSKVPeriod(`${financialYearStart}-${financialYearEnd}`) >=
    '2022P4';

  const months =
    differenceInMonths(parse(financialYearEnd), parse(financialYearStart)) + 1;

  const INK2 = mapFormId(form, 'INK2');
  const INK2R = mapFormId(form, 'INK2R');
  const INK2S = mapFormId(form, 'INK2S');
  const initial = {
    INK2: {
      partType: 'form' as const,
      id: INK2,
      fields: {
        orgNr: value(customer.orgNumber),
        date: value(''),
        companyName: value(customer.name),
        companyAddress1: value(customer.address),
        companyAddress2: value(''),
        '1_1': ref(
          sumAllowNull(id('INK2S.fields.4_15'), id('N11-*.N11.fields.A_1'))
        ),
        '1_2': ref(
          ifOrElse(
            id('INK2S.fields.4_15'),
            id('INK2S.fields.4_14_c_pos'),
            id('INK2S.fields.4_16')
          )
        ),
        '1_3': value(undefined),
        '1_4': ref(
          ifOrElse(
            id(
              'financialStatement.particularSalaryTax.isDetailedSpecification'
            ),
            onlyPositive(
              id(
                'financialStatement.particularSalaryTax.summarize.taxationBasis'
              )
            ),
            onlyPositive(id('financialStatement.particularSalaryTaxSum'))
          )
        ),
        '1_5': ref(
          ifOrElse(
            id(
              'financialStatement.particularSalaryTax.isDetailedSpecification'
            ),
            onlyNegative(
              id(
                'financialStatement.particularSalaryTax.summarize.taxationBasis'
              )
            ),
            onlyNegative(id('financialStatement.particularSalaryTaxSum'))
          )
        ),
        '1_6_a': value(undefined),
        '1_6_b': value(undefined),
        '1_7_a': value(undefined),
        '1_7_b': value(undefined),
        '1_8': value(undefined),
        '1_8_whole': value(undefined),
        '1_8_half': value(undefined),
        '1_9': value(undefined),
        '1_9_whole': value(undefined),
        '1_9_half': value(undefined),
        '1_10': value(undefined),
        '1_11': value(undefined),
        '1_12': value(undefined),
        '1_13': value(undefined),
        '1_14': value(undefined),
        '1_15': value(undefined),
        '1_16': value(undefined),
        '1_17': value(undefined),
        signature: value(''),
        name: value(customer.contactPerson),
        signatureClarification: value(customer.contactPerson),
        phoneNr: value(customer.phoneNumber),
        otherInfo: { type: 'boolean' as const, value: false },
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7104': ref(id('INK2.fields.1_1')),
        '7114': ref(id('INK2.fields.1_2')),
        '7131': ref(id('INK2.fields.1_3')),
        '7132': ref(id('INK2.fields.1_4')),
        '7133': ref(id('INK2.fields.1_5')),
        '7153': ref(id('INK2.fields.1_6_a')),
        '7154': ref(id('INK2.fields.1_6_b')),
        '7155': ref(id('INK2.fields.1_7_a')),
        '7156': ref(id('INK2.fields.1_7_b')),

        '80': ref(
          id(isFrom2022P4 ? 'INK2.fields.1_8' : 'INK2.fields.1_8_whole')
        ),
        ...(isFrom2022P4
          ? { '82': ref(id('INK2.fields.1_8_half')) }
          : undefined),
        '93': ref(
          id(isFrom2022P4 ? 'INK2.fields.1_9' : 'INK2.fields.1_9_whole')
        ),
        ...(isFrom2022P4
          ? { '94': ref(id('INK2.fields.1_9_half')) }
          : undefined),
        '84': ref(id('INK2.fields.1_10')),
        '86': ref(id('INK2.fields.1_11')),
        '95': ref(id('INK2.fields.1_12')),
        '96': ref(id('INK2.fields.1_13')),
        '97': ref(id('INK2.fields.1_14')),
        '98': ref(id('INK2.fields.1_15')),

        '90': ref(id('INK2.fields.otherInfo')),
        '1582': ref(id('INK2.fields.1_16')),
        '1586': ref(id('INK2.fields.1_17')),
      },
      help: {
        _type: 'INK2' as const,
        months: value(months),
        preliminaryTaxPerMonth: ref(
          multiply(
            div(
              id('finalTaxCalculations.taxCalculations.main.2.value'),
              id('INK2.help.months')
            ),
            -1
          )
        ),
      },
    },
    INK2R: {
      partType: 'form' as const,
      id: INK2R,
      fields: {
        orgNr: ref(id('INK2.fields.orgNr')),
        date: ref(id('INK2.fields.date')),
        '2_1': ref(
          sumAllowNull(
            balanceSheetRef(
              'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter'
            ),
            balanceSheetRef('HyresratterLiknandeRattigheter'),
            balanceSheetRef('Goodwill'),
            balanceSheetRef(
              'BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten'
            ),
            // Deprecated manual k2 v1 AR reference, no taxonomy name for this
            balanceSheetRef('otherIntangibleAssets')
          )
        ),
        '2_2': ref(
          sumAllowNull(
            balanceSheetRef('ForskottImmateriellaAnlaggningstillgangar'),
            balanceSheetRef(
              'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar'
            )
          )
        ),
        '2_3': ref(balanceSheetRef('ByggnaderMark')),
        '2_4': ref(
          sumAllowNull(
            balanceSheetRef('MaskinerAndraTekniskaAnlaggningar'),
            balanceSheetRef('InventarierVerktygInstallationer'),
            balanceSheetRef('OvrigaMateriellaAnlaggningstillgangar')
          )
        ),
        '2_5': ref(balanceSheetRef('ForbattringsutgifterAnnansFastighet')),
        '2_6': ref(
          balanceSheetRef(
            'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar'
          )
        ),
        '2_7': ref(balanceSheetRef('AndelarKoncernforetag')),
        '2_8': ref(
          balanceSheetRef('AndelarIntresseforetagGemensamtStyrdaForetag')
        ),
        '2_9': ref(
          sumAllowNull(
            balanceSheetRef('AgarintressenOvrigaForetag'),
            balanceSheetRef('AndraLangfristigaVardepappersinnehav')
          )
        ),
        '2_10': ref(
          sumAllowNull(
            balanceSheetRef('FordringarKoncernforetagLangfristiga'),
            balanceSheetRef(
              'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga'
            )
          )
        ),
        '2_11': ref(balanceSheetRef('LanDelagareNarstaende')),
        '2_12': ref(
          sumAllowNull(
            balanceSheetRef('FordringarOvrigaForetagAgarintresseLangfristiga'),
            balanceSheetRef('AndraLangfristigaFordringar'),
            balanceSheetRef('UppskjutenSkattefordran')
          )
        ),
        '2_13': ref(balanceSheetRef('LagerRavarorFornodenheter')),
        '2_14': ref(balanceSheetRef('LagerVarorUnderTillverkning')),
        '2_15': ref(balanceSheetRef('LagerFardigaVarorHandelsvaror')),
        '2_16': ref(balanceSheetRef('OvrigaLagertillgangar')),
        '2_17': ref(
          balanceSheetRef('PagaendeArbetenAnnansRakningOmsattningstillgangar')
        ),
        '2_18': ref(balanceSheetRef('ForskottTillLeverantorer')),
        '2_19': ref(balanceSheetRef('Kundfordringar')),
        '2_20': ref(
          sumAllowNull(
            balanceSheetRef('FordringarKoncernforetagKortfristiga'),
            balanceSheetRef(
              'FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga'
            )
          )
        ),
        '2_21': ref(
          sumAllowNull(
            balanceSheetRef('FordringarOvrigaforetagAgarintresseKortfristiga'),
            balanceSheetRef('OvrigaFordringarKortfristiga'),
            balanceSheetRef('AktuellSkattefodran')
          )
        ),
        '2_22': ref(balanceSheetRef('UpparbetadEjFaktureradIntakt')),
        '2_23': ref(balanceSheetRef('ForutbetaldaKostnaderUpplupnaIntakter')),
        '2_24': ref(balanceSheetRef('AndelarKoncernforetagKortfristiga')),
        '2_25': ref(balanceSheetRef('OvrigaKortfristigaPlaceringar')),
        '2_26': ref(
          sumAllowNull(
            balanceSheetRef('KassaBankExklRedovisningsmedel'),
            balanceSheetRef('Redovisningsmedel')
          )
        ),
        '2_27': ref(
          sumAllowNull(
            balanceSheetRef('Aktiekapital'),
            balanceSheetRef('EjRegistreratAktiekapital'),
            balanceSheetRef('OverkursfondBunden'),
            balanceSheetRef('Uppskrivningsfond'),
            balanceSheetRef('Reservfond'),
            balanceSheetRef('Kapitalandelsfond'),
            balanceSheetRef('FondUtvecklingsutgifter'),
            // Deprecated manual k2 v1 AR reference, no taxonomy name for this
            balanceSheetRef('equityManualK2')
          )
        ),
        '2_28': ref(
          sumAllowNull(
            balanceSheetRef('Overkursfond'),
            balanceSheetRef('FondVerkligtVarde'),
            balanceSheetRef('BalanseratResultat'),
            balanceSheetRef('ErhalletAktieAgartillskott'),
            balanceSheetRef('AretsResultatEgetKapital')
          )
        ),
        '2_29': ref(balanceSheetRef('Periodiseringsfonder')),
        '2_30': ref(balanceSheetRef('AckumuleradeOveravskrivningar')),
        '2_31': ref(
          sumAllowNull(
            balanceSheetRef('OvrigaObeskattadeReserver'),
            balanceSheetRef('Ersattningsfonder')
          )
        ),
        '2_32': ref(
          balanceSheetRef('AvsattningarPensionerLiknandeForpliktelserEnligtLag')
        ),
        '2_33': value(0),
        '2_34': ref(balanceSheetRef('OvrigaAvsattningar')),
        '2_35': ref(balanceSheetRef('Obligationslan')),
        '2_36': ref(balanceSheetRef('CheckrakningskreditLangfristig')),
        '2_37': ref(balanceSheetRef('OvrigaLangfristigaSkulderKreditinstitut')),
        '2_38': ref(
          sumAllowNull(
            balanceSheetRef('SkulderKoncernforetagLangfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga'
            )
          )
        ),
        '2_39': ref(
          sumAllowNull(
            balanceSheetRef('SkulderOvrigaForetagAgarintresseLangfristiga'),
            balanceSheetRef('OvrigaLangfristigaSkulder')
          )
        ),
        '2_40': ref(balanceSheetRef('CheckrakningskreditKortfristig')),
        '2_41': ref(balanceSheetRef('OvrigaKortfristigaSkulderKreditinstitut')),
        '2_42': ref(balanceSheetRef('ForskottFranKunder')),
        '2_43': ref(
          balanceSheetRef('PagaendeArbetenAnnansRakningKortfristigaSkulder')
        ),
        '2_44': ref(balanceSheetRef('FaktureradEjUpparbetadIntakt')),
        '2_45': ref(balanceSheetRef('Leverantorsskulder')),
        '2_46': ref(balanceSheetRef('Vaxelskulder')),
        '2_47': ref(
          sumAllowNull(
            balanceSheetRef('SkulderKoncernforetagKortfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga'
            )
          )
        ),
        '2_48': ref(
          sumAllowNull(
            balanceSheetRef('SkulderOvrigaForetagAgarintresseKortfristiga'),
            balanceSheetRef('OvrigaKortfristigaSkulder')
          )
        ),
        '2_49': ref(balanceSheetRef('Skatteskulder')),
        '2_50': ref(balanceSheetRef('UpplupnaKostnaderForutbetaldaIntakter')),
        '3_1_pos': ref(incomeStatementRef('Nettoomsattning')),
        '3_2_pos': ref(
          onlyPositive(
            incomeStatementRef(
              'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning'
            )
          )
        ),
        '3_2_neg': ref(
          onlyNegative(
            incomeStatementRef(
              'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning'
            )
          )
        ),
        '3_3_pos': ref(incomeStatementRef('AktiveratArbeteEgenRakning')),
        '3_4_pos': ref(incomeStatementRef('OvrigaRorelseintakter')),
        '3_5_neg': ref(
          negative(incomeStatementRef('RavarorFornodenheterKostnader'))
        ),
        '3_6_neg': ref(negative(incomeStatementRef('HandelsvarorKostnader'))),
        '3_7_neg': ref(negative(incomeStatementRef('OvrigaExternaKostnader'))),
        '3_8_neg': ref(negative(incomeStatementRef('Personalkostnader'))),
        '3_9_neg': ref(
          negative(
            incomeStatementRef(
              'AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar'
            )
          )
        ),
        '3_10_neg': ref(
          negative(
            incomeStatementRef(
              'NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar'
            )
          )
        ),
        '3_11_neg': ref(negative(incomeStatementRef('OvrigaRorelsekostnader'))),
        '3_12_pos': ref(
          onlyPositive(incomeStatementRef('ResultatAndelarKoncernforetag'))
        ),
        '3_12_neg': ref(
          onlyNegative(incomeStatementRef('ResultatAndelarKoncernforetag'))
        ),
        '3_13_pos': ref(
          onlyPositive(
            incomeStatementRef('ResultatAndelarIntresseforetagGemensamtStyrda')
          )
        ),
        '3_13_neg': ref(
          onlyNegative(
            incomeStatementRef('ResultatAndelarIntresseforetagGemensamtStyrda')
          )
        ),
        '3_14_pos': ref(
          onlyPositive(incomeStatementRef('ResultatOvrigaforetagAgarintresse'))
        ),
        '3_14_neg': ref(
          onlyNegative(incomeStatementRef('ResultatOvrigaforetagAgarintresse'))
        ),
        '3_15_pos': ref(
          onlyPositive(
            incomeStatementRef('ResultatOvrigaFinansiellaAnlaggningstillgangar')
          )
        ),
        '3_15_neg': ref(
          onlyNegative(
            incomeStatementRef('ResultatOvrigaFinansiellaAnlaggningstillgangar')
          )
        ),
        '3_16_pos': ref(
          incomeStatementRef('OvrigaRanteintakterLiknandeResultatposter')
        ),
        '3_17_neg': ref(
          negative(
            incomeStatementRef(
              'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar'
            )
          )
        ),
        '3_18_neg': ref(
          // Shares company K3 have a setting for this value different parts of table is active.
          // K2 and other company forms is RantekostnaderLiknandeResultatposter
          negative(
            sumAllowNull(
              ifOrElse(
                id('annualReport.settings.isNedskrivningEgenPost'),
                incomeStatementRef(
                  'RantekostnaderLiknandeResultatposterEgenPost'
                ),
                incomeStatementRef(
                  'RantekostnaderLiknandeResultatposterRespektivePost'
                )
              ),
              incomeStatementRef('RantekostnaderLiknandeResultatposter')
            )
          )
        ),
        '3_19_neg': ref(negative(incomeStatementRef('LamnadeKoncernbidrag'))),
        '3_20_pos': ref(incomeStatementRef('ErhallnaKoncernbidrag')),
        '3_21_pos': ref(onlyPositive(multiply(-1, account('8819')))),
        '3_22_neg': ref(onlyNegative(multiply(-1, account('8811')))),
        '3_23_pos': ref(
          onlyPositive(incomeStatementRef('ForandringOveravskrivningar'))
        ),
        '3_23_neg': ref(
          onlyNegative(incomeStatementRef('ForandringOveravskrivningar'))
        ),
        '3_24_pos': ref(
          onlyPositive(
            sumAllowNull(
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond'),
              incomeStatementRef('AndraBokslutsdispositioner')
            )
          )
        ),
        '3_24_neg': ref(
          onlyNegative(
            sumAllowNull(
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond'),
              incomeStatementRef('AndraBokslutsdispositioner')
            )
          )
        ),
        '3_25_neg': ref(
          sumAllowNull(
            negative(incomeStatementRef('SkattAretsResultat')),
            negative(incomeStatementRef('OvrigaSkatter'))
          )
        ),
        '3_26': ref(onlyPositive(incomeStatementRef('AretsResultat'))),
        '3_27': ref(onlyNegative(incomeStatementRef('AretsResultat'))),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7201': ref(id('INK2R.fields.2_1')),
        '7202': ref(id('INK2R.fields.2_2')),
        '7214': ref(id('INK2R.fields.2_3')),
        '7215': ref(id('INK2R.fields.2_4')),
        '7216': ref(id('INK2R.fields.2_5')),
        '7217': ref(id('INK2R.fields.2_6')),
        '7230': ref(id('INK2R.fields.2_7')),
        '7231': ref(id('INK2R.fields.2_8')),
        '7233': ref(id('INK2R.fields.2_9')),
        '7232': ref(id('INK2R.fields.2_10')),
        '7234': ref(id('INK2R.fields.2_11')),
        '7235': ref(id('INK2R.fields.2_12')),
        '7241': ref(id('INK2R.fields.2_13')),
        '7242': ref(id('INK2R.fields.2_14')),
        '7243': ref(id('INK2R.fields.2_15')),
        '7244': ref(id('INK2R.fields.2_16')),
        '7245': ref(id('INK2R.fields.2_17')),
        '7246': ref(id('INK2R.fields.2_18')),
        '7251': ref(id('INK2R.fields.2_19')),
        '7252': ref(id('INK2R.fields.2_20')),
        '7261': ref(id('INK2R.fields.2_21')),
        '7262': ref(id('INK2R.fields.2_22')),
        '7263': ref(id('INK2R.fields.2_23')),
        '7270': ref(id('INK2R.fields.2_24')),
        '7271': ref(id('INK2R.fields.2_25')),
        '7281': ref(id('INK2R.fields.2_26')),
        '7301': ref(id('INK2R.fields.2_27')),
        '7302': ref(id('INK2R.fields.2_28')),
        '7321': ref(id('INK2R.fields.2_29')),
        '7322': ref(id('INK2R.fields.2_30')),
        '7323': ref(id('INK2R.fields.2_31')),
        '7331': ref(id('INK2R.fields.2_32')),
        '7332': ref(id('INK2R.fields.2_33')),
        '7333': ref(id('INK2R.fields.2_34')),
        '7350': ref(id('INK2R.fields.2_35')),
        '7351': ref(id('INK2R.fields.2_36')),
        '7352': ref(id('INK2R.fields.2_37')),
        '7353': ref(id('INK2R.fields.2_38')),
        '7354': ref(id('INK2R.fields.2_39')),
        '7360': ref(id('INK2R.fields.2_40')),
        '7361': ref(id('INK2R.fields.2_41')),
        '7362': ref(id('INK2R.fields.2_42')),
        '7363': ref(id('INK2R.fields.2_43')),
        '7364': ref(id('INK2R.fields.2_44')),
        '7365': ref(id('INK2R.fields.2_45')),
        '7366': ref(id('INK2R.fields.2_46')),
        '7367': ref(id('INK2R.fields.2_47')),
        '7369': ref(id('INK2R.fields.2_48')),
        '7368': ref(id('INK2R.fields.2_49')),
        '7370': ref(id('INK2R.fields.2_50')),
        '7410': ref(id('INK2R.fields.3_1_pos')),
        '7411': ref(id('INK2R.fields.3_2_pos')),
        '7510': ref(id('INK2R.fields.3_2_neg')),
        '7412': ref(id('INK2R.fields.3_3_pos')),
        '7413': ref(id('INK2R.fields.3_4_pos')),
        '7511': ref(id('INK2R.fields.3_5_neg')),
        '7512': ref(id('INK2R.fields.3_6_neg')),
        '7513': ref(id('INK2R.fields.3_7_neg')),
        '7514': ref(id('INK2R.fields.3_8_neg')),
        '7515': ref(id('INK2R.fields.3_9_neg')),
        '7516': ref(id('INK2R.fields.3_10_neg')),
        '7517': ref(id('INK2R.fields.3_11_neg')),
        '7414': ref(id('INK2R.fields.3_12_pos')),
        '7518': ref(id('INK2R.fields.3_12_neg')),
        '7415': ref(id('INK2R.fields.3_13_pos')),
        '7519': ref(id('INK2R.fields.3_13_neg')),
        '7423': ref(id('INK2R.fields.3_14_pos')),
        '7530': ref(id('INK2R.fields.3_14_neg')),
        '7416': ref(id('INK2R.fields.3_15_pos')),
        '7520': ref(id('INK2R.fields.3_15_neg')),
        '7417': ref(id('INK2R.fields.3_16_pos')),
        '7521': ref(id('INK2R.fields.3_17_neg')),
        '7522': ref(id('INK2R.fields.3_18_neg')),
        '7524': ref(id('INK2R.fields.3_19_neg')),
        '7419': ref(id('INK2R.fields.3_20_pos')),
        '7420': ref(id('INK2R.fields.3_21_pos')),
        '7525': ref(id('INK2R.fields.3_22_neg')),
        '7421': ref(id('INK2R.fields.3_23_pos')),
        '7526': ref(id('INK2R.fields.3_23_neg')),
        '7422': ref(id('INK2R.fields.3_24_pos')),
        '7527': ref(id('INK2R.fields.3_24_neg')),
        '7528': ref(id('INK2R.fields.3_25_neg')),
        '7450': ref(id('INK2R.fields.3_26')),
        '7550': ref(id('INK2R.fields.3_27')),
      },
    },
    INK2S: {
      partType: 'form' as const,
      id: INK2S,
      fields: {
        orgNr: ref(id('INK2.fields.orgNr')),
        date: ref(id('INK2.fields.date')),
        '4_1_pos': ref(id('INK2R.fields.3_26')),
        '4_2_neg': ref(id('INK2R.fields.3_27')),
        '4_3_a_pos': ref(
          sumAllowNull(
            negative(incomeStatementRef('SkattAretsResultat')),
            negative(incomeStatementRef('OvrigaSkatter'))
          )
        ),
        '4_3_b_pos': ref(id('INK2R.fields.3_17_neg')),
        '4_3_c_pos': ref(
          sumAllowNull(
            id('N9-*.N9.fields.C_5_overfort'),
            id('N9-*.N9.fields.E_4_skattemassig_aterforing'),
            id('N9-*.N9.fields.E_6_ytterligare'),
            id('financialStatement.nonDeductibleExpenses'),
            multiply(0.3, id('INK2.fields.1_7_b')),
            multiply(-1, id('INK2R.fields.3_17_neg'))
          )
        ),

        '4_4_a_neg': value(),
        '4_4_b_neg': ref(
          sumAllowNull(
            id('N9-*.N9.fields.C_2_overtaget'),
            id('N9-*.N9.fields.E_5_skattemassig_avdrag')
          )
        ),

        '4_5_a_neg': value(),
        '4_5_b_neg': value(),
        '4_5_c_neg': ref(negative(id('financialStatement.nonTaxableIncomes'))),

        '4_6_a_pos': ref(id('financialStatement.templateIncomeAccrualFund')),
        '4_6_b_pos': value(),
        '4_6_c_pos': value(),
        '4_6_d_pos': ref(id('financialStatement.calculationAccrual')),
        '4_6_e_pos': ref(
          sumAllowNull(id('N11-*.N11.fields.C_1'), id('N11-*.N11.fields.C_2'))
        ),

        '4_7_a_neg': value(),
        '4_7_b_pos': ref(
          sumAllowEmpty(
            id('INK2R.fields.3_12_neg'),
            id('INK2R.fields.3_13_neg'),
            id('INK2R.fields.3_14_neg'),
            id('INK2R.fields.3_15_neg')
          )
        ),
        '4_7_c_neg': ref(sumAllowNull(id('N4-*.N4.fields.A_2_vinst'))),
        '4_7_d_pos': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_3_tidigare_uppskov'),
            id('N4-*.N4.fields.A_4_uppskov_som_ska_aterforas'),
            id('N4-*.N4.fields.B_1_uppskov_som_ska_aterforas')
          )
        ),
        '4_7_e_pos': value(),
        '4_7_f_neg': value(),

        '4_8_a_neg': value(),
        '4_8_b_pos': value(),
        '4_8_c_pos': value(),
        '4_8_d_neg': value(),

        '4_9_pos': value(),
        '4_9_neg': value(),

        '4_10_pos': value(),
        '4_10_neg': value(),

        '4_11_neg': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_1_summa'),
            id('N8-*.N8.fields.B_1_belopp')
          )
        ),

        '4_12_pos': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_2_summa'),
            id('N8-*.N8.fields.B_2_belopp')
          )
        ),

        '4_13_pos': value(),
        '4_13_neg': value(),

        '4_14_a_neg': ref(absolute(id('financialStatement.deficitDeduction'))), // INK2 Previous year 1.2
        '4_14_b_pos': value(),
        '4_14_c_pos': value(),

        '4_15_16': ref(
          sum(
            or(id('INK2S.fields.4_1_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_2_neg'), 0)),
            or(id('INK2S.fields.4_3_a_pos'), 0),
            or(id('INK2S.fields.4_3_b_pos'), 0),
            or(id('INK2S.fields.4_3_c_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_4_a_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_4_b_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_5_a_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_5_b_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_5_c_neg'), 0)),
            or(id('INK2S.fields.4_6_a_pos'), 0),
            or(id('INK2S.fields.4_6_b_pos'), 0),
            or(id('INK2S.fields.4_6_c_pos'), 0),
            or(id('INK2S.fields.4_6_d_pos'), 0),
            or(id('INK2S.fields.4_6_e_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_7_a_neg'), 0)),
            or(id('INK2S.fields.4_7_b_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_7_c_neg'), 0)),
            or(id('INK2S.fields.4_7_d_pos'), 0),
            or(id('INK2S.fields.4_7_e_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_7_f_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_8_a_neg'), 0)),
            or(id('INK2S.fields.4_8_b_pos'), 0),
            or(id('INK2S.fields.4_8_c_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_8_d_neg'), 0)),
            or(id('INK2S.fields.4_9_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_9_neg'), 0)),
            or(id('INK2S.fields.4_10_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_10_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_11_neg'), 0)),
            or(id('INK2S.fields.4_12_pos'), 0),
            or(id('INK2S.fields.4_13_pos'), 0),
            multiply(-1, or(id('INK2S.fields.4_13_neg'), 0)),
            multiply(-1, or(id('INK2S.fields.4_14_a_neg'), 0)),
            or(id('INK2S.fields.4_14_b_pos'), 0),
            or(id('INK2S.fields.4_14_c_pos'), 0)
          )
        ),
        '4_15': ref(onlyPositive(id('INK2S.fields.4_15_16'))),
        '4_16': ref(onlyNegative(id('INK2S.fields.4_15_16'))),
        '4_17': value(),
        '4_18': value(),
        '4_19': value(),
        '4_20': value(),
        '4_21': ref(id('INK2.fields.1_4')),
        '4_22': value(),
        contractorAssisted_yes: {
          type: 'boolean' as const,
          value: false,
          oppositeCellId: 'contractorAssisted_no',
        },
        contractorAssisted_no: {
          type: 'boolean' as const,
          value: false,
          oppositeCellId: 'contractorAssisted_yes',
        },
        annualReportAudit_yes: {
          type: 'boolean' as const,
          value: false,
          oppositeCellId: 'annualReportAudit_no',
        },
        annualReportAudit_no: {
          type: 'boolean' as const,
          value: false,
          oppositeCellId: 'annualReportAudit_yes',
        },
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7650': ref(id('INK2S.fields.4_1_pos')),
        '7750': ref(id('INK2S.fields.4_2_neg')),
        '7651': ref(id('INK2S.fields.4_3_a_pos')),
        '7652': ref(id('INK2S.fields.4_3_b_pos')),
        '7653': ref(id('INK2S.fields.4_3_c_pos')),
        '7751': ref(id('INK2S.fields.4_4_a_neg')),
        '7764': ref(id('INK2S.fields.4_4_b_neg')),
        '7752': ref(id('INK2S.fields.4_5_a_neg')),
        '7753': ref(id('INK2S.fields.4_5_b_neg')),
        '7754': ref(id('INK2S.fields.4_5_c_neg')),
        '7654': ref(id('INK2S.fields.4_6_a_pos')),
        '7668': ref(id('INK2S.fields.4_6_b_pos')),
        '7655': ref(id('INK2S.fields.4_6_c_pos')),
        '7673': ref(id('INK2S.fields.4_6_d_pos')),
        '7665': ref(id('INK2S.fields.4_6_e_pos')),
        '7755': ref(id('INK2S.fields.4_7_a_neg')),
        '7656': ref(id('INK2S.fields.4_7_b_pos')),
        '7756': ref(id('INK2S.fields.4_7_c_neg')),
        '7657': ref(id('INK2S.fields.4_7_d_pos')),
        '7658': ref(id('INK2S.fields.4_7_e_pos')),
        '7757': ref(id('INK2S.fields.4_7_f_neg')),
        '7758': ref(id('INK2S.fields.4_8_a_neg')),
        '7659': ref(id('INK2S.fields.4_8_b_pos')),
        '7660': ref(id('INK2S.fields.4_8_c_pos')),
        '7759': ref(id('INK2S.fields.4_8_d_neg')),
        '7666': ref(id('INK2S.fields.4_9_pos')),
        '7765': ref(id('INK2S.fields.4_9_neg')),
        '7661': ref(id('INK2S.fields.4_10_pos')),
        '7760': ref(id('INK2S.fields.4_10_neg')),
        '7761': ref(id('INK2S.fields.4_11_neg')),
        '7662': ref(id('INK2S.fields.4_12_pos')),
        '7663': ref(id('INK2S.fields.4_13_pos')),
        '7762': ref(id('INK2S.fields.4_13_neg')),
        '7763': ref(id('INK2S.fields.4_14_a_neg')),
        '7671': ref(id('INK2S.fields.4_14_b_pos')),
        '7672': ref(id('INK2S.fields.4_14_c_pos')),
        '7670': ref(id('INK2S.fields.4_15')),
        '7770': ref(id('INK2S.fields.4_16')),
        '8020': ref(id('INK2S.fields.4_17')),
        '8021': ref(id('INK2S.fields.4_18')),
        '8023': ref(id('INK2S.fields.4_19')),
        '8026': ref(id('INK2S.fields.4_20')),
        '8022': ref(id('INK2S.fields.4_21')),
        '8028': ref(id('INK2S.fields.4_22')),
        // Uppdragstagare (t.ex. redovisningskonsult) har biträtt vid upprättandet av årsredovisningen: Ja	8040
        '8040': ref(id('INK2S.fields.contractorAssisted_yes')),
        // Uppdragstagare (t.ex. redovisningskonsult) har biträtt vid upprättandet av årsredovisningen: Nej	8041
        '8041': ref(id('INK2S.fields.contractorAssisted_no')),
        // Årsredovisningen har varit föremål för revision: Ja	8044
        '8044': ref(id('INK2S.fields.annualReportAudit_yes')),
        // Årsredovisningen har varit föremål för revision: Nej	8045
        '8045': ref(id('INK2S.fields.annualReportAudit_no')),
      },
    },
  };

  const additionalPages = {
    finalTaxCalculations: {
      ...getFinalCalculationsMetadata({
        customer,
        financialYearStart,
        financialYearEnd,
      }),
      taxReturnsPositive: value('Beräknat belopp att betala (exklusive ränta)'),
      taxReturnsNegative: value('Beräknat belopp att få tillbaka'),
      finalTax: table(
        'finalTaxCalculations.finalTax',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Statlig inkomstskatt/Bolagsskatt'),
                  ref(id('financialStatement.companyTax')),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Fastighetsskatt'),
                  ref(id('financialStatement.propertyTaxTotal')),
                  value(true)
                )
                .addRow(
                  '3',
                  value('Särskild löneskatt'),
                  ref(
                    ifOrElse(
                      id(
                        'financialStatement.particularSalaryTax.isDetailedSpecification'
                      ),
                      id(
                        'financialStatement.particularSalaryTax.summarize.particularSalaryTax'
                      ),
                      id('financialStatement.particularSalaryTaxFromSum')
                    )
                  ),
                  value(true)
                )
                .addRow('4', value('Avkastningsskatt'), value(0), value(true))
                .addRow(
                  '5',
                  value('Skattereduktion för förnybar el'),
                  value(0),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              value('Slutlig skatt'),
              ref(sumAllowEmpty(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .build()
        )
        .build(),
      taxCalculations: table(
        'finalTaxCalculations.taxCalculations',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Slutlig skatt'),
                  ref(sum(id('finalTaxCalculations.finalTax.main.*.value'))),
                  value(true)
                )
                .addRow('2', value('Preliminär skatt'), value(0), value(true))
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(sum(id(`${rows.getBaseId()}.main.*.value`)), 0),
                  id('finalTaxCalculations.taxReturnsPositive'),
                  id('finalTaxCalculations.taxReturnsNegative')
                )
              ),
              ref(abs(sumAllowEmpty(id(`${rows.getBaseId()}.main.*.value`))))
            )
            .build()
        )
        .build(),
    },
  };

  return customer.type === 'shares' || customer.type === 'economic_association'
    ? toConfig(
        initial,
        form,
        { INK2: helpStructureINK2 },
        { finalTaxCalculations },
        additionalPages
      )
    : {
        initial: toInitial(initial, form),
        definition: toStructure(initial, form),
      };
};

export default configINK2;
