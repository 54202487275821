import React, { useState, ReactElement, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import Arrow from '@material-ui/icons/Forward';
import styled from '@emotion/styled';

import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { Period } from '@agoy/api-sdk-core';
import { MAX_NUM_DOCUMENTS } from 'contants';
import {
  PreviewModal,
  DocumentUpload,
} from '_shared/components/UploadDocument';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import Button from '_shared/components/Buttons/Button';
import DocumentPreviewItem from './DocumentPreviewItem';
import PeriodDataContext from '../PeriodDataContext';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
`;

const LoadingWrapper = styled.div`
  margin: 70px auto;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftIcon = styled(Arrow)`
  transform: scaleX(-1);
`;

interface DocumentClippyProps {
  periodInformation: ReactElement;
  period: Period;
  groupId: string;
  handleSelectDocument: (file: File, id: number) => void;
}

const DocumentClippy = ({
  periodInformation,
  period,
  groupId,
  handleSelectDocument,
}: DocumentClippyProps): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  // STATE
  const [copyLoading, setCopyLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayedDocumentId, setDisplayedDocumentId] = useState<number | null>(
    null
  );
  const [previousPeriodDocumentsCount, setPreviousPeriodDocumentsCount] =
    useState(0);

  const {
    documents,
    loadingDocuments,
    uploadingDocuments,
    refetchDocuments,
    copyDocumentsFromPreviousPeriod,
    getPreviousPeriodDocumentsCount,
  } = useAccountDocuments();

  const { previousPeriod, previousPeriodFinancialYear } =
    useContext(PeriodDataContext);

  useEffect(() => {
    const getDocumentsCount = async () => {
      if (previousPeriod && previousPeriodFinancialYear) {
        const documentsCount = await getPreviousPeriodDocumentsCount();

        setPreviousPeriodDocumentsCount(documentsCount);
      }
      return 0;
    };
    getDocumentsCount();
  }, [
    previousPeriod,
    previousPeriodFinancialYear,
    getPreviousPeriodDocumentsCount,
  ]);

  // CALLBACKS

  const handleOpenPreviewModal = (index) => {
    setIsModalOpen(true);
    setDisplayedDocumentId(index);
  };

  const handleClosePreviewModal = () => {
    setIsModalOpen(false);
    setDisplayedDocumentId(null);
  };

  const handleCopyFromPreviousPeriod = async () => {
    if (previousPeriod && previousPeriodFinancialYear) {
      try {
        setCopyLoading(true);
        await copyDocumentsFromPreviousPeriod();
      } catch (error) {
        dispatch(addGlobalErrorMessage('error', 'error'));
      } finally {
        setCopyLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      {loadingDocuments ? (
        <LoadingWrapper>
          <LoadingPlaceholder />
        </LoadingWrapper>
      ) : (
        <>
          {documents.length < MAX_NUM_DOCUMENTS && (
            <UploadContainer>
              <DocumentUpload
                message={formatMessage({ id: 'records.uploadNewRecord' })}
                isUploading={uploadingDocuments}
                onUpload={handleSelectDocument}
                period={period}
              />
              <Button
                label={formatMessage({
                  id: 'hidden.transactions.copyFromPrevious',
                })}
                disabled={!previousPeriodDocumentsCount || copyLoading}
                onClick={handleCopyFromPreviousPeriod}
                size="small"
                variant="text"
                startIcon={<LeftIcon />}
              />
            </UploadContainer>
          )}

          {documents.map((document, index) => (
            <DocumentPreviewItem
              id={index}
              period={period}
              groupId={groupId}
              document={document}
              onOpenPreviewModal={handleOpenPreviewModal}
              key={document.id}
            />
          ))}
        </>
      )}

      {displayedDocumentId !== null && (
        <PreviewModal
          documentSrc={documents[displayedDocumentId].url || ''}
          documentName={documents[displayedDocumentId].name || ''}
          documentId={documents[displayedDocumentId].name || ''}
          information={
            <>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'base' })}
              </Typography>
              <Typography variant="body1">
                {`${displayedDocumentId + 1}/${documents.length}`}
              </Typography>
              {periodInformation}
            </>
          }
          isOpen={isModalOpen && !!documents[displayedDocumentId]}
          onStepBackwards={() =>
            displayedDocumentId - 1 >= 0
              ? setDisplayedDocumentId(displayedDocumentId - 1)
              : setDisplayedDocumentId(documents.length - 1)
          }
          onStepForward={() =>
            displayedDocumentId + 1 < documents.length
              ? setDisplayedDocumentId(displayedDocumentId + 1)
              : setDisplayedDocumentId(0)
          }
          onClose={handleClosePreviewModal}
          refreshDownloadUrl={refetchDocuments}
        />
      )}
    </Wrapper>
  );
};

export default DocumentClippy;
