import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, Typography } from '@material-ui/core';
import { Warning, LockOpen } from '@material-ui/icons';
import styled from '@emotion/styled';
import { compareDesc } from 'date-fns';

import { useSelector } from 'redux/reducers';
import Button from '_shared/components/Buttons/Button';
import UnlockReasonModal from '_shared/components/UnlockReasonModal/UnlockReasonModal';
import { LockedPeriodError } from 'data/client/accountingBalances/types';
import { ccyFormat, getMatchingTimePeriod, mapOf } from '@agoy/common';
import { Period } from '@agoy/api-sdk-core';
import { parseFormat, parse } from '@agoy/dates';
import { trackCustomEvent } from '@fnox/web-analytics-script';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 800px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(5)}px
    ${({ theme }) => theme.spacing(4)}px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
`;

const Text = styled(Typography)`
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`;

const BoldText = styled(Text)`
  font-weight: 500;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const TableContainer = styled.div`
  display: grid;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
`;

const HeaderRow = styled(GridRow)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const HeaderTitle = styled(Typography)`
  font-weight: 600;
`;

const Content = styled.div`
  background-color: #ededed;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
`;

const PeriodText = styled(Typography)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

const WarningIcon = styled(Warning)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

const LockOpenIcon = styled(LockOpen)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const typeTranslation = {
  NEW_VOUCHER: 'sie.periodLockedError.voucher',
  CHANGED_VOUCHER: 'sie.periodLockedError.voucher',
  REMOVED_VOUCHER: 'sie.periodLockedError.voucher',
  NEW_TRANSACTION: 'sie.periodLockedError.transaction',
  CHANGED_TRANSACTION: 'sie.periodLockedError.transaction',
  REMOVED_TRANSACTION: 'sie.periodLockedError.transaction',
  ACCOUNT_BALANCES_CHANGED: 'sie.periodLockedError.balance',
};

type LockedPeriodErrors = LockedPeriodError['errorDetails'];

type LockedPeriodErrorDialogProps = {
  clientId: string;
  lockedPeriodErrors: LockedPeriodErrors;
  onSubmit: (period: Period, reason: string) => void;
  onClose: () => void;
  loading?: boolean;
};

type ErrorWithPeriod = LockedPeriodErrors[number] & {
  period?: Period;
};

const LockedPeriodErrorDialog = ({
  clientId,
  lockedPeriodErrors,
  onSubmit,
  onClose,
  loading = false,
}: LockedPeriodErrorDialogProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [sortedErrors, setSortedErrors] = useState<ErrorWithPeriod[]>([]);
  const [isReasonOpen, setIsReasonOpen] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (open) {
      trackCustomEvent({
        eventCategory: 'reconciliations',
        eventAction: 'period_adjustment:view',
        eventName: 'modal:changes_locked_period',
      });
    }
  }, [open]);

  const financialYears = useSelector(
    (state) => state.customers[clientId]?.rawFinancialYears
  );
  const closingPeriod = useSelector(
    (state) => state.customers[clientId]?.closingPeriod ?? ('month' as const)
  );

  const periodsById = useMemo(
    () =>
      financialYears
        ? mapOf(
            financialYears.flatMap((fy) => fy.periods ?? []),
            (p) => p.id,
            (p) => p
          )
        : new Map<number, Period>(),
    [financialYears]
  );

  const firstPeriod = useMemo(() => {
    if (Array.isArray(sortedErrors) && sortedErrors.length) {
      const { period } = sortedErrors[0];
      if (period) {
        const timePeriod = getMatchingTimePeriod(
          closingPeriod,
          period,
          financialYears
        );
        if (timePeriod) {
          return financialYears
            .flatMap((y) => y.periods ?? [])
            .find((p) => p.start === timePeriod.start);
        }
      }
      return undefined;
    }

    return undefined;
  }, [sortedErrors, closingPeriod, financialYears]);

  useEffect(() => {
    if (Array.isArray(lockedPeriodErrors)) {
      const errors = lockedPeriodErrors.map(
        (error): ErrorWithPeriod => ({
          ...error,
          period: periodsById.get(error.periodId),
        })
      );

      errors.sort((a, b) => {
        const aPeriod = a.period;
        const bPeriod = b.period;

        if (aPeriod && bPeriod) {
          return compareDesc(parse(bPeriod.start), parse(aPeriod.start));
        }

        return 1;
      });

      setSortedErrors(errors);
    }
  }, [lockedPeriodErrors, financialYears, periodsById]);

  const getText = (key: string) =>
    formatMessage({ id: `sie.periodLockedError.${key}` });

  const handleSubmit = () => {
    setOpen(false);
    setIsReasonOpen(true);
    trackCustomEvent({
      eventCategory: 'reconciliations',
      eventAction: 'period_adjustment:import',
      eventName: 'modal:changes_locked_period',
    });
  };

  const handleSubmitReason = (reason: string) => {
    if (firstPeriod) {
      onSubmit(firstPeriod, reason);
      setIsReasonOpen(false);
    }
  };

  return (
    <>
      {firstPeriod && (
        <UnlockReasonModal
          open={isReasonOpen}
          period={firstPeriod}
          onClose={() => setIsReasonOpen(false)}
          onSubmit={handleSubmitReason}
        />
      )}

      <StyledDialog fullWidth open={open} onClose={onClose}>
        <Container>
          <TitleWrapper>
            <WarningIcon />
            <Title>{getText('title')}</Title>
          </TitleWrapper>

          <Text>{getText('subtitle')}</Text>
          <BoldText>{getText('errors')}</BoldText>

          <TableContainer>
            <HeaderRow>
              <HeaderTitle>{getText('table.period')}</HeaderTitle>
              <HeaderTitle>{getText('table.type')}</HeaderTitle>
              <HeaderTitle>{getText('table.ver')}</HeaderTitle>
              <HeaderTitle>{getText('table.account')}</HeaderTitle>
              <HeaderTitle>{getText('table.changeIB')}</HeaderTitle>
            </HeaderRow>

            <Content>
              {Array.isArray(sortedErrors) &&
                sortedErrors.map((item) => {
                  const periodById = item.period
                    ? periodsById.get(item.period.id)
                    : undefined;

                  const formattedPeriod = periodById
                    ? parseFormat(periodById.start, 'MMM-yy')
                    : '';

                  return (
                    <GridRow
                      key={`${item.periodId}${item.type}${
                        'account' in item ? item.account : ''
                      }`}
                    >
                      <PeriodText>{formattedPeriod}</PeriodText>
                      <Typography>
                        {formatMessage({ id: typeTranslation[item.type] })}
                      </Typography>
                      <Typography>
                        {'voucher' in item ? item.voucher : ''}
                      </Typography>
                      <Typography>
                        {'account' in item ? item.account : ''}
                      </Typography>
                      <Typography>
                        {'changeIB' in item ? ccyFormat(item.changeIB) : ''}
                      </Typography>
                    </GridRow>
                  );
                })}
            </Content>
          </TableContainer>

          <BoldText>{getText('options')}</BoldText>
          <Text>{getText('options.first')}</Text>
          <Text>{getText('options.second')}</Text>

          <Buttons>
            <Button
              label={getText('unlock')}
              onClick={handleSubmit}
              loading={loading}
              startIcon={<LockOpenIcon />}
            />
            <Button
              label={getText('cancel')}
              onClick={onClose}
              variant="text"
              disabled={loading}
            />
          </Buttons>
        </Container>
      </StyledDialog>
    </>
  );
};

export default LockedPeriodErrorDialog;
