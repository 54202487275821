import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Paper, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import ImageDropArea from './ImageDropArea';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import { getAdditionalReconciliationPeriodData } from 'utils/piwik';
import { Period } from '@agoy/api-sdk-core';

const Wrapper = styled(Paper)`
  margin: 1rem;
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.accountingView.cell.ok};
  border: 2px dashed ${({ theme }) => theme.palette.secondary.main};

  &.small {
    width: 180px;
    height: 180px;

    .MuiButton-label {
      font-size: 0.7rem;
    }
  }

  input {
    display: none;
  }

  :hover {
    cursor: pointer;
  }
`;

const UploadRecordText = styled(Typography)`
  text-align: center;
  margin: ${({ theme }) => `${theme.spacing(2)}px 0`};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}px`};
  white-space: normal;
`;

const DocumentInput = styled.input`
  display: none;
`;

export interface DocumentUploadProps {
  message: string;
  onUpload: (file: File, id: number) => void;
  size?: 'small' | 'normal';
  isUploading?: boolean;
  className?: string;
  period?: Period;
}

const DocumentUpload = ({
  message,
  isUploading = false,
  onUpload,
  size = 'normal',
  className,
  period,
}: DocumentUploadProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const inputField = useRef<HTMLInputElement>(null);
  const [fileInput, setFileInput] = useState('');

  const handledDocumentUpload = () => {
    inputField.current?.click?.();
  };

  const handleSelectDocument = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (!files) {
      return;
    }
    const filesKeys = Object.keys(files);

    filesKeys.forEach((key, i) => {
      const file = files[key];
      if (file) {
        trackCustomEvent({
          eventCategory: 'annual_accounts_taxes',
          eventAction: 'document:upload',
          eventName: 'method:file_upload_button',
          data: getAdditionalReconciliationPeriodData(period),
        });
        onUpload(file, i);
        setFileInput('');
      }
    });
  };

  const handleDrop = (file: File, id: number) => {
    onUpload(file, id);
  };

  const isSmall = size === 'small';

  return (
    <ImageDropArea onDrop={handleDrop} period={period}>
      <Wrapper
        className={`${isSmall ? 'small' : ''}, ${className || ''}`}
        elevation={2}
        onClick={handledDocumentUpload}
      >
        <Button
          label={formatMessage({
            id: 'hidden.uploadImage',
          })}
          size="medium"
          variant="outlined"
          loading={isUploading}
          onClick={() => {
            handledDocumentUpload();
          }}
        />
        <UploadRecordText
          variant={isSmall ? 'body2' : 'body1'}
          color="textPrimary"
        >
          {message}
        </UploadRecordText>
        <DocumentInput
          className="fileInput"
          multiple
          ref={inputField}
          type="file"
          accept="jpg, jpeg, png, gif, tiff, pdf"
          onChange={handleSelectDocument}
          value={fileInput}
        />
      </Wrapper>
    </ImageDropArea>
  );
};

export default DocumentUpload;
